import { render, staticRenderFns } from "./CourseChange.vue?vue&type=template&id=4183ac58&scoped=true&"
import script from "./CourseChange.vue?vue&type=script&lang=js&"
export * from "./CourseChange.vue?vue&type=script&lang=js&"
import style0 from "./CourseChange.vue?vue&type=style&index=0&id=4183ac58&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4183ac58",
  null
  
)

export default component.exports